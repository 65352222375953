import {RefObject, useRef} from 'react'
import {FormObject} from '@components/forms/form.t'
import {TabSingleManager} from '@components/tabs/custom-tab/tab-single-form/manager'
import {asArray} from '@libs/utils'
import {invalidateDomainQueries, useErpDomainsQuery} from '@queries'
import {LookupItem, LookupPager} from '@shared/erp-api'
import {DomainKey, DomainKeyPlural, ErpDomain, Tab, TabComponentType} from '@shared/interfaces'
import {QueryClient, useQueryClient} from '@tanstack/react-query'
import {FormikProps} from 'formik'
import {MutableRefObject, useMemo, useState} from 'react'
import DomainHelper from '@libs/helpers/domains'
import {TabListAndFormManager} from '@components/tabs/custom-tab/tab-list-and-form/manager'
import {UseRefreshResult} from '@hooks/use-refresh'

export type SubStateValues = Partial<
  {[key in DomainKey]: LookupItem} & {[key in DomainKeyPlural]: LookupItem[]} & {
    [key in `${DomainKey}FormikRef`]: MutableRefObject<FormikProps<FormObject> | null>
  }
>

export interface ICustomTabManager {
  component: TabComponentType
  read: () => Promise<{data?: FormObject; err?: object}>
  refresh: () => void
  invalidateQueries?: UseRefreshResult
}

export type CustomTabManager = TabSingleManager | TabListAndFormManager

export interface CustomTabState {
  tab: Tab
  manager?: CustomTabManager
  item?: FormObject
  itemList?: LookupItem[]
  pager?: LookupPager
  page?: number
  search?: string
  formikRef?: RefObject<FormikProps<FormObject>>
}

export interface TabStateAccessors {
  tabs: Record<string, CustomTabState>
  setTab(tab: Tab, manager?: CustomTabManager): void
  getManager(tab: Tab | string): CustomTabManager | undefined
  setTabItem(tab: Tab, item?: FormObject): void
  getTabItem(tab: Tab): FormObject | undefined
  getTabItemList(tab: Tab): LookupItem[] | undefined
  setTabItemList(tab: Tab, itemList: LookupItem[]): void
  getTabPager(tab: Tab): LookupPager | undefined
  setTabPager(tab: Tab, pager: LookupPager): void
  getTabPage(tab: Tab): number
  setTabPage(tab: Tab, page: number): void
  getTabSearch(tab: Tab): string | undefined
  setTabSearch(tab: Tab, search: string): void
  getFormikRef(tab: Tab): RefObject<FormikProps<FormObject>> | undefined
  setFormikRef(tab: Tab, ref: RefObject<FormikProps<FormObject>>): void
}

export interface SubStateAccessors {
  getItem(domainKey: DomainKey): LookupItem | undefined
  setItem(domainKey: DomainKey, item?: LookupItem): void
  getList(domainKey: DomainKey): LookupItem[] | undefined
  setList(domainKey: DomainKey, items?: LookupItem[]): void
  getFormikRef(
    domainKey: DomainKey,
    current?: boolean
  ): MutableRefObject<FormikProps<FormObject> | null> | FormikProps<FormObject> | undefined | null
  setFormikRef(domainKey: DomainKey, ref: MutableRefObject<FormikProps<FormObject> | null>): void
  refreshTab(domainKey: DomainKey, id?: string): Promise<void>
  refresh(domainKeys: DomainKey[] | string): Promise<void>
}

export type SubStateManager = SubStateValues & SubStateAccessors

export interface StateManager {
  queryClient: QueryClient
  sub: SubStateManager
  Tabs: TabStateAccessors
}

export const useStateManager = (): StateManager | undefined => {
  const [sub, setSub] = useState<SubStateValues>({})
  const [tabs, setTabs] = useState<Record<string, CustomTabState>>({})

  const tabsRef = useRef<Record<string, CustomTabState>>({})
  tabsRef.current = tabs

  const erpDomains = useErpDomainsQuery()
  const queryClient = useQueryClient()

  const erpDomainsByDomainKey = useMemo(() => {
    return erpDomains.data?.reduce(
      (acc, curr) => {
        acc[curr.key] = curr
        return acc
      },
      {} as {[key in DomainKey]: ErpDomain}
    )
  }, [erpDomains.data])

  if (!erpDomainsByDomainKey) return undefined

  return {
    queryClient,
    sub: {
      ...sub,
      getItem(domainKey) {
        return sub[domainKey]
      },
      setItem(domainKey, item) {
        setSub((prev) => ({...prev, [domainKey]: item}))
      },
      getList(domainKey) {
        return sub[erpDomainsByDomainKey[domainKey].keyPlural]
      },
      setList(domainKey, items) {
        setSub((prev) => ({...prev, [erpDomainsByDomainKey[domainKey].keyPlural]: items}))
      },
      setFormikRef(domainKey, ref) {
        setSub((prev) => ({...prev, [`${domainKey}FormikRef`]: ref}))
      },
      getFormikRef(domainKey, current = true) {
        return current ? (sub[`${domainKey}FormikRef`] || {}).current : sub[`${domainKey}FormikRef`]
      },
      async refreshTab(domainKey, id) {
        await invalidateDomainQueries(queryClient, erpDomainsByDomainKey[domainKey].keyPlural, id)
      },
      async refresh(domainKeys) {
        const domainKeysAsArray = asArray(domainKeys) as DomainKey[]

        await Promise.all(
          domainKeysAsArray.flatMap((domainKey) => {
            const erpDomain = erpDomainsByDomainKey[domainKey]

            const parentDomains = DomainHelper(erpDomain)
              .getParents()
              .map((key) => erpDomainsByDomainKey[key].keyPlural)

            return [...parentDomains, erpDomain.keyPlural].map((domainKeyPlural) =>
              invalidateDomainQueries(queryClient, domainKeyPlural, sub[domainKey]?._id)
            )
          })
        )
      }
    },
    Tabs: {
      tabs,
      setTab(tab: Tab, manager?: CustomTabManager) {
        setTabs((prev) => ({...prev, [tab.key]: {tab, manager}}))
      },
      getManager(tab: Tab | string) {
        const tabKey = typeof tab === 'string' ? tab : tab.key
        const result: CustomTabManager | undefined = tabsRef.current[tabKey]?.manager
        return (
          result && {
            ...result,
            item: tabsRef.current[tabKey]?.item
          }
        )
      },
      setTabItem(tab: Tab, item?: FormObject) {
        setTabs((prev) => ({...prev, [tab.key]: {...prev[tab.key], item}}))
      },
      getTabItem(tab: Tab) {
        return tabsRef.current[tab.key]?.item
      },
      getTabItemList: function (tab: Tab): LookupItem[] | undefined {
        return tabsRef.current[tab.key]?.itemList
      },
      setTabItemList: function (tab: Tab, itemList: LookupItem[]): void {
        setTabs((prev) => ({...prev, [tab.key]: {...prev[tab.key], itemList}}))
      },
      getTabPager: function (tab: Tab): LookupPager | undefined {
        return tabsRef.current[tab.key]?.pager
      },
      setTabPager: function (tab: Tab, pager: LookupPager): void {
        setTabs((prev) => ({...prev, [tab.key]: {...prev[tab.key], pager}}))
      },
      getTabPage: function (tab: Tab): number {
        return tabsRef.current[tab.key]?.page || 1
      },
      setTabPage: function (tab: Tab, page: number): void {
        setTabs((prev) => ({...prev, [tab.key]: {...prev[tab.key], page}}))
      },
      getTabSearch: function (tab: Tab): string | undefined {
        return tabsRef.current[tab.key]?.search
      },
      setTabSearch: function (tab: Tab, search: string): void {
        setTabs((prev) => ({...prev, [tab.key]: {...prev[tab.key], search}}))
      },
      getFormikRef: function (tab: Tab) {
        return tabsRef.current[tab.key]?.formikRef
      },
      setFormikRef: function (tab: Tab, ref: RefObject<FormikProps<FormObject>>) {
        setTabs((prev) => ({...prev, [tab.key]: {...prev[tab.key], formikRef: ref}}))
      }
    }
  }
}
