import {DeleteOutlined, EditOutlined} from '@ant-design/icons'
import {FormObject} from '@components/forms/form.t'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Action, ErpTable} from '@shared/interfaces'
import {ItemType} from 'antd/lib/menu/interface'
import {useCallback, useMemo} from 'react'

type UseActionMenuItemsResult = (record: FormObject) => ItemType[]

const useActionMenuItems = (table: ErpTable, actions: Action[]): UseActionMenuItemsResult => {
  const getEditAction = useCallback(
    (record: FormObject): ItemType[] => {
      if (table.extendable && table.forms?.edit)
        return [
          {
            key: `edit-${record._id}`,
            icon: <EditOutlined />,
            label: <a className='bold'>Modifier</a>
          }
        ]

      return []
    },
    [table]
  )

  const getDeleteAction = useCallback(
    (record: FormObject): ItemType[] => {
      if (table.extendable)
        return [
          {
            key: `delete-${record._id}`,
            icon: <DeleteOutlined />,
            danger: true,
            label: <a className='bold'>Supprimer</a>
          }
        ]

      return []
    },
    [table]
  )

  const getCustomActions = useCallback((): ItemType[] => {
    if (actions?.length)
      return actions.map((action) => ({
        key: `action-${action._id}`,
        icon: action.icon && <FontAwesomeIcon icon={action.icon} />,
        label: <a className='bold'>{action.name}</a>
      }))

    return []
  }, [actions])

  const handlers = useMemo(
    () => [getEditAction, getDeleteAction, getCustomActions],
    [getEditAction, getDeleteAction, getCustomActions]
  )

  const getItems = useCallback(
    (record: FormObject) => {
      const items: ItemType[] = []
      return handlers.reduce((result, fn) => result.concat(fn(record)), items)
    },
    [handlers]
  )

  return getItems
}

export default useActionMenuItems
