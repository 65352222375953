import React, {useState} from 'react'
import {atPath} from '@libs/utils'
import {DateTimeInputType} from '@shared/interfaces'
import {useApp} from '@store/app'
import {useField} from 'formik'
import _ from 'lodash'
import moment, {Moment} from 'moment-timezone'
import 'moment-timezone'
import * as yup from 'yup'
import {FieldComponentFactory, FieldComponentProps} from '../../fields.t'
import TimePicker from './TimePicker'
import TimePickerRange from './TimePickerRange'
import {IntRange} from 'rc-picker/lib/interface'
import FormItem from '../form-item/form-item'

interface TimeInputComponentProps {
  _id: string
  key: string
  label: string
  name: string
  type: string
  placeholder?: string
  required?: boolean
  disabled?: boolean
  defaultValue?: string
  hourStep?: IntRange<1, 23> | undefined
  minuteStep?: IntRange<1, 59> | undefined
}

const format = 'HH:mm'

const TimeInputComponent: React.FC<FieldComponentProps<TimeInputComponentProps>> = ({field}) => {
  const {label, ...props} = field

  const [formikField, meta, helpers] = useField<string | string[] | null>({...props})

  const {t} = useApp()

  // example: 1:30 => 01:30
  function formatOldTime(value: string): string {
    const [hour, minute] = value.split(':').map(Number)
    const formattedHour = hour < 10 ? `0${hour}` : String(hour)
    const formattedMinute = minute < 10 ? `0${minute}` : String(minute)
    return `${formattedHour}:${formattedMinute}`
  }

  const [wasCleared, setWasCleared] = useState(false)

  const handleOnChange = (value: Moment | null | [Moment | null, Moment | null]) => {
    // suggest defaultValue on first clear
    if (!value && !wasCleared) {
      if (field.defaultValue) {
        const defaultMoment = moment(field.defaultValue, format)
        helpers.setValue(formatOldTime(defaultMoment.format(format)))
        setWasCleared(true)
        return
      }
    }
    if (value && Array.isArray(value)) {
      // TimePickerRange
      const formattedValue = value
        .map((v) => (v ? formatOldTime(v.format(format)) : null))
        .filter(Boolean)
      helpers.setValue(formattedValue as string[])
    } else if (value) {
      // TimePicker
      helpers.setValue(formatOldTime(value.format(format)))
    } else if (field.type === DateTimeInputType['time-range']) {
      // Allow to clear the field
      helpers.setValue(null)
    } else {
      helpers.setValue(null)
    }
    setWasCleared(false)
  }

  const handleOnFocus = () => {
    if (formikField.value && wasCleared) return
    // suggest defaultValue on focus
    if (field.defaultValue) {
      const defaultMoment = moment(field.defaultValue, format)
      helpers.setValue(formatOldTime(defaultMoment.format(format)))
      setWasCleared(true)
    }
  }

  return (
    <FormItem field={field} style={{flex: 1}} className='w-100 mb-2'>
      {field.type === DateTimeInputType['time'] ? (
        <TimePicker
          className='w-100'
          onChange={handleOnChange}
          placeholder={t('time-input.timepicker.placeholder') ?? undefined}
          format={format}
          rawValue={typeof formikField.value === 'string' ? formikField.value : undefined}
          disabled={field.disabled}
          onFocus={handleOnFocus}
          showNow={false}
          hourStep={field.hourStep ?? 1}
          minuteStep={field.minuteStep ?? 15}
        />
      ) : field.type === DateTimeInputType['time-range'] ? (
        <TimePickerRange
          {...formikField}
          onChange={handleOnChange}
          format={format}
          value={[
            formikField.value?.[0] ? moment(formikField.value[0], format) : null,
            formikField.value?.[1] ? moment(formikField.value[1], format) : null
          ]}
          disabled={field.disabled}
        />
      ) : null}
    </FormItem>
  )
}

const TimeInput: FieldComponentFactory = (field) => {
  return {
    initialValue(data) {
      const value = data && atPath(data, field.key)

      if (data && value !== undefined && value !== '') {
        return value
      } else if (field.type === DateTimeInputType['time-range']) {
        return [undefined, undefined]
      } else {
        return field.defaultValue || undefined
      }
    },
    validationSchema() {
      let schema

      if (field.type === DateTimeInputType['time-range']) {
        schema = yup.array().of(yup.string().nullable()).nullable()
      } else {
        schema = yup.string().nullable()
      }

      if (field.required) schema = schema.required()

      return {[field.key]: schema}
    },
    generateComponent() {
      return <TimeInputComponent field={_.omit(field, 'hidden', 'ref')} />
    }
  }
}

export default TimeInput
