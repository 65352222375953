import {useMemo, ReactNode} from 'react'
import {Menu, Row, Space} from 'antd'
import {MenuItemProps, useNavItems} from './useNavItems'
import InmemoriEnvironment from './inmemoriEnvironment'
import {useErpDomainsQuery} from '@queries'
import {useRouter} from 'next/router'
import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {fab} from '@fortawesome/free-brands-svg-icons'
import {fas} from '@fortawesome/pro-solid-svg-icons'
import {ErpDomain} from '@shared/interfaces'
import {ItemType} from 'antd/lib/menu/interface'
import {AppContext, withApp} from '@store/app'
import {AnyMongoAbility} from '@casl/ability'
import Link from 'next/link'

interface MenuItem {
  label: string | ReactNode
  key: string
  keyPlural?: string
  icon?: ReactNode
  children?: MenuItem[]
  directPath?: boolean
  isStatic?: boolean
}

const findItemByKey = (items: MenuItem[], key: string): MenuItem | undefined => {
  const foundItem = items.find((item) => item.key === key)

  if (foundItem) return foundItem

  const foundChildItem = items
    .filter((item) => item.children)
    .map((item) => item.children && findItemByKey(item.children, key))
    .find(Boolean)

  return foundChildItem
}

const convertNavItem = (
  navItem: MenuItemProps,
  erpDomainsData: ErpDomain[] | undefined,
  ability?: AnyMongoAbility
): MenuItem | undefined => {
  const erpDomainFound = erpDomainsData?.find((domain) => domain.key === navItem.key)

  if (erpDomainFound && ability?.cannot('crud.access', erpDomainFound?.key)) return

  const children = navItem.children
    ?.map((item) => convertNavItem(item, erpDomainsData, ability))
    .filter(Boolean) as MenuItem[]

  if (navItem.children?.length && !children.length) return

  const ret: MenuItem = {
    // navItem before erpDomainFound to override label and keyPlural
    label: navItem.label || erpDomainFound?.namePlural,
    key: navItem.key,
    keyPlural: navItem.keyPlural || erpDomainFound?.keyPlural,
    directPath: navItem.directPath,
    isStatic: Boolean(navItem.isStatic),
    icon:
      (erpDomainFound?.icon && <FontAwesomeIcon icon={erpDomainFound.icon} />) ||
      (navItem.icon && <FontAwesomeIcon icon={navItem.icon} />),
    ...(navItem.children && {
      children: navItem.children
        .map((item) => convertNavItem(item, erpDomainsData, ability))
        .filter(Boolean) as MenuItem[]
    })
  }
  return ret
}

const convertItemType = ({
  label,
  key,
  icon,
  children,
  directPath,
  keyPlural
}: MenuItem): ItemType => {
  // under some conditions it should not be a link but a plain label (ex: logout, switch, profile, key with childrens to avoid undefined links)
  const linkOrPlainLabel =
    children || key === 'houseSwitch' || key === 'profileDropdown' ? (
      label
    ) : (
      <Link className='navbar-menu-a' href={directPath ? `/${key}` : `/domain/${keyPlural}`}>
        {label}
      </Link>
    )

  return {
    label: linkOrPlainLabel,
    key,
    icon,
    children: children?.map(convertItemType) || undefined
  }
}

const NavBar = ({ability}: AppContext) => {
  library.add(fas, fab) // import all icons dynamically

  const router = useRouter()

  const navItems = useNavItems()

  const redirect = (eKey: string, directPath: boolean): void => {
    if (directPath)
      router.push(`/${eKey}`).catch((e) => {
        // workaround for https://github.com/vercel/next.js/issues/37362
        if (!e.cancelled) {
          throw e
        }
      })
    else
      router.push(`/domain/${eKey}`).catch((e) => {
        // workaround for https://github.com/vercel/next.js/issues/37362
        if (!e.cancelled) {
          throw e
        }
      })
  }

  const erpDomains = useErpDomainsQuery()

  const navItemsUpdated = useMemo(() => {
    if (erpDomains.isSuccess) {
      return navItems
        .map((item) => convertNavItem(item, erpDomains.data, ability))
        .filter(Boolean) as MenuItem[]
    }
    return
  }, [ability, erpDomains.data, erpDomains.isSuccess, navItems])

  return (
    <Row className='navbar-container'>
      <InmemoriEnvironment />
      {!!navItemsUpdated && (
        <Space size={'large'} style={{marginLeft: 'auto'}}>
          <Menu
            items={navItemsUpdated.map(convertItemType)}
            onClick={(info) => {
              if (info.domEvent.ctrlKey || info.domEvent.metaKey) {
                // it opens a new tab without changing current one when cmd+click
                return
              }
              const selectedItem = findItemByKey(navItemsUpdated, info.key)
              !selectedItem?.isStatic &&
                redirect(selectedItem?.keyPlural || info.key, Boolean(selectedItem?.directPath))
            }}
            disabledOverflow
            mode='horizontal'
            className='navbar-menu'
          />
        </Space>
      )}
    </Row>
  )
}

export default withApp(NavBar)
