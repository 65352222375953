import * as React from 'react'
import DatePicker from '../date-input/DatePicker'
import moment, {Moment} from 'moment-timezone'
import 'moment-timezone'
import {PickerTimeProps, RangePickerTimeProps} from 'antd/lib/time-picker'

interface TimePickerProps extends Omit<PickerTimeProps<Moment>, 'picker' | 'value'> {
  rawValue?: string
  format: string
}

const TimePicker = React.forwardRef<never, TimePickerProps>((props, ref) => (
  <DatePicker
    {...props}
    picker='time'
    mode={undefined}
    ref={ref}
    value={props.rawValue ? moment(props.rawValue, props.format) : null}
  />
))

export const RangePicker = React.forwardRef<never, Omit<RangePickerTimeProps<Moment>, 'picker'>>(
  (props, ref) => <DatePicker.RangePicker {...props} picker='time' ref={ref} />
)

RangePicker.displayName = 'TimeRangePicker'

TimePicker.displayName = 'TimePicker'

export default TimePicker
