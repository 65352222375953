/* eslint-disable react/display-name */
import _ from 'lodash'
import {AnyMongoAbility} from '@casl/ability'
import {datadogRum} from '@datadog/browser-rum'
import getAbility from '@libs/ability'
import {AbilityContext} from '@libs/can'
import {Panels} from '@libs/panels/panels.t'
import {Toast} from '@libs/toast'
import * as Utils from '@libs/utils'
import {useErpPermissionsQuery} from '@queries'
import {useGetProfileSelfQuery} from '@queries/profiles'
import {APIProxyV1Service, AuthService, useInmemoriServices} from '@services'
import {CRM, useCRM} from '@services/crm'
import {StateManager, useStateManager} from '@services/state-manager'
import {ViewManager, useViewManager} from '@services/view-manager'
import {Profile} from '@shared/interfaces'
import {ModalStaticFunctions} from 'antd/lib/modal/confirm'
import {TFunction} from 'next-i18next'
import {PropsWithChildren, default as React, createContext, useEffect, useMemo} from 'react'
import {useHotkeys} from 'react-hotkeys-hook'

export interface AppContext {
  Panels: Panels
  t: TFunction
  toast: Toast
  modal: Omit<ModalStaticFunctions, 'warn'>
  api: APIProxyV1Service
  Auth?: AuthService
  ability?: AnyMongoAbility
  $profile?: Profile
  CRM?: CRM
  VM?: ViewManager
  Datadog: {
    trackAction: (name: string, customAttributes?: object) => void
    trackError: (error: unknown, customAttributes?: object) => void
  }
  stateManager?: StateManager
  utils?: typeof Utils
  isInitialized?: boolean
}

export const AppContext = createContext<AppContext>({} as AppContext)
export const AppProvider = ({children, ...props}: PropsWithChildren<Omit<AppContext, 'api'>>) => {
  const {apiProxyV1Service: api, authService: Auth} = useInmemoriServices()
  const permissions = useErpPermissionsQuery()
  const $profile = useGetProfileSelfQuery()
  const CRM = useCRM()
  const VM = useViewManager()

  useHotkeys('ctrl+m', VM.toggleViewMode)

  useEffect(() => {
    if ($profile.data) {
      datadogRum.setUser({
        id: $profile.data._id,
        ..._.pick($profile.data, [
          '_id',
          'cat',
          'counter',
          'createdBy',
          'email',
          'firstname',
          'houses',
          'lastname',
          'meta',
          'name',
          'phone',
          'ref'
        ])
      })
    }
  }, [$profile.data, $profile.isSuccess])

  const ability = useMemo(
    () => (permissions.isSuccess ? getAbility(permissions.data) : undefined),
    [permissions.data, permissions.isSuccess]
  )

  const stateManager = useStateManager()

  if (process.browser) window.SM = stateManager

  const isInitialized = useMemo<boolean>(
    () => permissions.isFetched && $profile.isFetched,
    [permissions.isFetched, $profile.isFetched]
  )

  return (
    <AbilityContext.Provider value={ability}>
      <AppContext.Provider
        value={{
          ...props,
          api,
          Auth,
          CRM,
          ability,
          $profile: $profile.data,
          utils: Utils,
          stateManager,
          VM,
          isInitialized
        }}>
        {children}
      </AppContext.Provider>{' '}
    </AbilityContext.Provider>
  )
}
export const AppConsumer = AppContext.Consumer

export const withApp =
  <P extends object>(Component: React.ComponentType<P>): React.FC<Omit<P, keyof AppContext>> =>
  (props) => <AppConsumer>{(store) => <Component {...(props as P)} {...store} />}</AppConsumer>

export function useApp() {
  const store = React.useContext(AppContext)
  if (!store) {
    throw new Error('useApp must be used within an AppProvider')
  }
  return store
}
