import {AxiosResponse, RawAxiosRequestHeaders} from 'axios'
import {APIService} from './api'
import {ErpResponse, LookupItem, LookupResults} from '@shared/erp-api'

export interface APIProxyV1Params {
  thraw?: boolean
  headers?: RawAxiosRequestHeaders
  body?: unknown
}

export interface APIProxyV1Error {
  code: string
  message: object
}

/**
 * This is a proxy service that will be used to call the API using V1 syntax (using the fetch API)
 */
export class APIProxyV1Service {
  constructor(
    private api: APIService,
    private baseUrl: string
  ) {
    this.baseUrl = baseUrl.replace(/\/+$/, '')
  }

  private treatResult<T>(
    result: AxiosResponse<ErpResponse<T>>,
    thraw = false
  ): T | APIProxyV1Error {
    const body = result.data
    const ret =
      result.status >= 400
        ? {statusCode: result.status, code: result.statusText, message: body as object}
        : body.data

    if (thraw && result.status >= 400) throw ret

    return ret
  }

  public async get<T = unknown>(path: string, params: APIProxyV1Params = {}) {
    const result = await this.api.get<ErpResponse<T>>(`${this.baseUrl}${path}`, {
      headers: params.headers,
      params: params.body,
      validateStatus: undefined
    })

    return this.treatResult(result, params.thraw)
  }

  public async delete<T = unknown>(path: string, params: APIProxyV1Params = {}) {
    const result = await this.api.delete<ErpResponse<T>>(`${this.baseUrl}${path}`, {
      headers: params.headers,
      params: params.body,
      validateStatus: undefined
    })

    return this.treatResult(result, params.thraw)
  }

  public async post<T = unknown>(path: string, params: APIProxyV1Params = {}) {
    const result = await this.api.post<ErpResponse<T>>(`${this.baseUrl}${path}`, params.body, {
      headers: params.headers,
      validateStatus: undefined
    })

    return this.treatResult(result, params.thraw)
  }
  public async put<T = unknown>(path: string, params: APIProxyV1Params = {}) {
    const result = await this.api.put<ErpResponse<T>>(`${this.baseUrl}${path}`, params.body, {
      headers: params.headers,
      validateStatus: undefined
    })

    return this.treatResult(result, params.thraw)
  }

  public async lookup(domain: string, {pattern = '', page = 0, q = {}} = {}) {
    const body = {search: pattern, q}
    const path = `/${domain}/lookup${page ? `?page=${page}` : ''}`

    const result = await this.api.post<ErpResponse<LookupResults<LookupItem>>>(
      `${this.baseUrl}${path}`,
      body,
      {
        validateStatus: undefined
      }
    )

    return this.treatResult(result)
  }
}
