import {useInmemoriServices} from '@services'
import {TaskStatus} from '@shared/interfaces'
import {QueryClient, useMutation, useQuery} from '@tanstack/react-query'
import {erpKeys} from './erp'

export function useUpdateTasksStatusMutation() {
  const erpApi = useInmemoriServices().erpApiService

  return useMutation({
    mutationFn: ({
      tasks,
      status,
      value,
      tabId
    }: {
      tasks: string[]
      status: keyof TaskStatus
      value: boolean
      tabId: string
    }) => {
      return erpApi.updateTasksStatus(tasks, status, value, tabId)
    }
  })
}

export function useGetChecklistQuery(projectId?: string) {
  const erpApi = useInmemoriServices().erpApiService

  return useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: projectId ? erpKeys.projects._ctx.checklist(projectId).queryKey : [],
    queryFn: () =>
      erpApi.getChecklist(projectId).then(({data}) => {
        return data.data
      }),
    gcTime: 0,
    enabled: Boolean(projectId),
    staleTime: 0,
    refetchOnWindowFocus: false
  })
}

export function invalidateGetChecklistQuery(queryClient: QueryClient, projectId?: string) {
  return queryClient.invalidateQueries({
    queryKey: projectId ? erpKeys.projects._ctx.checklist(projectId).queryKey : []
  })
}

export function useGetTimelineQuery(projectId?: string) {
  const erpApi = useInmemoriServices().erpApiService

  return useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: projectId ? erpKeys.projects._ctx.timeline(projectId).queryKey : [],
    queryFn: () =>
      erpApi.getTimeline(projectId).then(({data}) => {
        return data.data
      }),
    gcTime: 0,
    enabled: Boolean(projectId),
    staleTime: 0,
    refetchOnWindowFocus: false
  })
}

export function invalidateGetTimelineQuery(queryClient: QueryClient, projectId?: string) {
  return queryClient.invalidateQueries({
    queryKey: projectId ? erpKeys.projects._ctx.timeline(projectId).queryKey : []
  })
}
