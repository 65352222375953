import * as Yup from 'yup'
import React, {Dispatch, useEffect, useMemo, useReducer} from 'react'
import {atPath} from '@libs/utils'
import {Collapse, Typography} from 'antd'

import {FormDomain} from '@shared/interfaces'
import {
  FieldComponentFactory,
  FieldComponentProps,
  FieldWrapper
} from '@components/forms/fields/fields.t'
import _ from 'lodash'
import {formInitalValues, initializeForm} from '@components/forms/utils'
import {ValidationAction, ValidationActionKind, ValidationState} from '@components/forms/form.t'
import FormLayout from '@components/forms/layout/form-layout'
import {formValidationReducer} from '@components/forms/validation'
import usePrevious from '@libs/helpers/usePrevious'
import resolveLabel from '@libs/helpers/resolve-label'

const {Title} = Typography

interface SubformInputFieldComponentProps {
  key: string
  label: string
  name: string
  required?: boolean
  disabled?: boolean
  subform?: string

  /** if true, the subform fields will be added to the parent form */
  flat?: boolean
}

interface SubformInputComponentProps extends FieldComponentProps<SubformInputFieldComponentProps> {
  form: FormDomain
  fields: FieldWrapper[]
  collapsible?: boolean
  dispatchValidation: Dispatch<ValidationAction>
}

export const SubformInputComponent: React.FC<SubformInputComponentProps> = ({
  field,
  form,
  fields,
  collapsible = true,
  dispatchValidation
}: SubformInputComponentProps) => {
  const {label, name, flat} = field

  const [validationState, subDispatchValidation] = useReducer(formValidationReducer, {
    schema: Yup.object()
  })

  const prevValidationState = usePrevious<ValidationState>(validationState)

  useEffect(() => {
    if (flat) {
      if (prevValidationState) {
        if (_.isEqual(prevValidationState.schema.fields, validationState.schema.fields)) return
        else {
          const missingFields = _.difference(
            Object.keys(prevValidationState.schema.fields),
            Object.keys(validationState.schema.fields)
          )

          if (missingFields.length) {
            dispatchValidation({
              kind: ValidationActionKind.REMOVE_KEYS,
              keys: missingFields
            })
          }
        }
      }
      dispatchValidation({
        kind: ValidationActionKind.MERGE_SCHEMA,
        schema: validationState.schema
      })
    } else {
      const schema = Yup.object().shape({
        [field.key]: Yup.object().shape({...validationState.schema.fields})
      })

      dispatchValidation({
        kind: ValidationActionKind.MERGE_SCHEMA,
        schema
      })
    }

    return () => {
      dispatchValidation({
        kind: ValidationActionKind.REMOVE_KEYS,
        keys: Object.keys(validationState.schema.fields)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.key, flat, validationState])

  const collapseItems = useMemo(() => {
    return [
      {
        key: name,
        label: <Title level={5}>{resolveLabel(field)}</Title>,
        children: (
          <FormLayout form={form} fields={fields} dispatchValidation={subDispatchValidation} />
        )
      }
    ]
  }, [field, fields, form, name])

  if (!collapsible) {
    return <FormLayout form={form} fields={fields} dispatchValidation={subDispatchValidation} />
  }

  return <Collapse items={collapseItems} defaultActiveKey={[name]} style={{flex: 1}}></Collapse>
}

const SubformInput: FieldComponentFactory = (
  field,
  forms,
  options = {collapsible: true},
  ability,
  formData
) => {
  const subform = forms.find(({_id}) => _id === field.subform)
  if (!subform) {
    console.error(
      '##### SUBFORM NOT FOUND #####\n',
      'Subform with id',
      field.subform,
      'not found',
      {field, subformId: field.subform}
    )
    return {
      initialValue() {
        return null
      },
      validationSchema() {
        return {}
      },
      generateComponent() {
        return null
      }
    }
  }

  const subFormfields = initializeForm(
    subform,
    forms,
    field.flat ? undefined : field.name,
    formData,
    ability,
    formData?.__type as string,
    field.disabled
  )

  return {
    initialValue(data) {
      const value = data && atPath(data, field.key)
      return formInitalValues(subFormfields, field.flat ? data : value)
    },
    //Validation handled by the component itself
    validationSchema() {
      return {}
    },
    generateComponent(dispatchValidation) {
      return (
        <SubformInputComponent
          form={subform}
          fields={subFormfields}
          collapsible={options.collapsible}
          field={_.omit(field, 'hidden', 'ref')}
          dispatchValidation={dispatchValidation}
        />
      )
    }
  }
}
export default SubformInput
