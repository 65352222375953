import React from 'react'
import {RangePicker} from './TimePicker'
import {Moment} from 'moment-timezone'
import 'moment-timezone'
import {NoUndefinedRangeValueType} from 'rc-picker/lib/PickerInput/RangePicker'

interface TimePickerRangeProps {
  format?: string
  value?: [Moment | null, Moment | null]
  defaultValue?: [Moment | null, Moment | null]
  onChange?: (value: [Moment | null, Moment | null]) => void
  disabled?: boolean
}

const TimePickerRange: React.FC<TimePickerRangeProps> = ({
  format = 'HH:mm',
  value,
  defaultValue,
  onChange,
  disabled = false,
  ...rest
}) => {
  const timePickerProps = {
    format: format,
    value: value,
    defaultValue: defaultValue,
    picker: 'time' as const,
    ...rest
  }

  const handleOnChange = (values: NoUndefinedRangeValueType<Moment> | null) => {
    if (onChange) {
      onChange(values as [Moment | null, Moment | null])
    }
  }

  return <RangePicker {...timePickerProps} onChange={handleOnChange} disabled={disabled} />
}

export default TimePickerRange
