import React, {useMemo, useState} from 'react'
import {useField, useFormikContext} from 'formik'
import Yup from '@libs/yup'
import _ from 'lodash'
import {atPath} from '@libs/utils'
import {FieldComponentFactory, FieldComponentProps} from '@components/forms/fields/fields.t'
import FormItem from '../form-item/form-item'
import {InboxOutlined} from '@ant-design/icons'
import type {UploadProps, UploadFile} from 'antd'
import {Upload} from 'antd'
import {useApp} from '@store/app'

const {Dragger} = Upload

interface FileUploadInputProps {
  key: string
  label: string
  name: string
  required?: boolean
  disabled?: boolean
}

const FileUploadInputComponent: React.FC<FieldComponentProps<FileUploadInputProps>> = ({field}) => {
  const [formikField] = useField<File>(field)
  const {setFieldValue} = useFormikContext()
  const {t} = useApp()

  const [fileList, setFileList] = useState<UploadFile[]>([])

  const draggerProps: UploadProps = useMemo(
    () => ({
      name: 'file',
      multiple: false,
      accept: '.csv, application/json',
      fileList,
      beforeUpload: (selectedFile) => {
        const newFile: UploadFile = {
          uid: String(Date.now()),
          name: selectedFile.name,
          status: 'done',
          originFileObj: selectedFile
        }

        setFileList([newFile])
        setFieldValue(formikField.name, selectedFile)
        return false // Prevents automatic upload
      },
      onRemove: () => {
        setFileList([])
        setFieldValue(formikField.name, null)
      }
    }),
    [fileList, formikField.name, setFieldValue]
  )

  return (
    <FormItem field={field} style={{flex: 1}} className='mb-2'>
      <Dragger {...draggerProps}>
        <p className='ant-upload-drag-icon'>
          <InboxOutlined />
        </p>
        <p className='ant-upload-text'>{t('components.forms.fields.fileUpload.chooseFile')}</p>
        <p className='ant-upload-hint'>{t('components.forms.fields.fileUpload.fileHint')}</p>
      </Dragger>
    </FormItem>
  )
}

const FileUploadInput: FieldComponentFactory = (field) => {
  return {
    initialValue(data) {
      const value = data && atPath(data, field.key)
      if (data && value !== undefined) return value
      return field.defaultValue || null
    },
    validationSchema() {
      const schema = Yup.mixed()
      return {
        [field.key]: field.required ? schema.required('Un fichier est requis') : schema.nullable()
      }
    },
    generateComponent() {
      return <FileUploadInputComponent field={_.omit(field, 'hidden', 'ref')} />
    }
  }
}

export default FileUploadInput
