import React, {useMemo} from 'react'
import {TaskDetailsView} from './tm.t'
import ActionMenu from './actions-menu'
import {Task} from '@shared/interfaces'
import {Checkbox, Col, Row, Tag} from 'antd'
import {CalendarOutlined} from '@ant-design/icons'
import {useApp} from '@store/app'
import moment from 'moment-timezone'
import 'moment-timezone'
import {PersonType} from '@components/dashboard/dashboard.t'

interface TaskManagementTaskItemProps {
  task: TaskDetailsView
  rawTask: Task
  done: boolean
  onToggleTaskCheckbox: (checked: boolean, taskId: string) => void
}

const TaskManagementTaskItem: React.FC<TaskManagementTaskItemProps> = ({
  task,
  done,
  rawTask,
  onToggleTaskCheckbox
}) => {
  const {ability} = useApp()
  const taskNameStyle = useMemo(() => (done ? 'tm-task-done' : ''), [done])

  const disabled = useMemo(() => {
    return ability?.cannot('crud.update', task)
  }, [ability, task])

  const taskInfos = useMemo(() => {
    const {
      done: {checked: done, by: doneBy = {firstname: '', lastname: ''}, at: doneAt},
      disabled: {checked: disabled, by: disabledBy = {firstname: '', lastname: ''}, at: disabledAt}
    } = task.status

    const format = 'DD/MM'
    const formattedDoneAt = moment(doneAt).format(format)
    const formattedDisabledAt = moment(disabledAt).format(format)

    // prioritize displaying disabled info
    const icon = disabled ? '⊖' : done ? '✔' : ''

    // olds projects don't have a .by field
    const formatStatus = (icon: string, date: string, by: PersonType) => {
      const getName = (person: PersonType) =>
        person ? `${person.firstname || ''} ${person.lastname || ''}`.trim() : ''
      const name = getName(by)
      return `${icon} ${date}` + (name ? ` - ${name}` : '')
    }

    return disabled
      ? formatStatus(icon, formattedDisabledAt, disabledBy)
      : done
        ? formatStatus(icon, formattedDoneAt, doneBy)
        : ''
  }, [task])

  return (
    <React.Fragment>
      <Row className='tm-task-item-container my-1'>
        <Col span={1} className='tm-task-checkbox'>
          <Checkbox
            disabled={disabled}
            checked={done}
            onChange={(e) => {
              onToggleTaskCheckbox(e.target.checked, rawTask._id)
            }}
          />
        </Col>
        <Col span={14} className='pr-4 my-1'>
          <div>
            <span className={`tm-task-name ${taskNameStyle}`}>{task.name}</span>
            {taskInfos && <span className='tm-task-done-at-by'>{taskInfos}</span>}
          </div>
          {task.comment && <div className='tm-task-comment'>{task.comment}</div>}
        </Col>
        <Col span={5}>
          <div style={{display: 'flex'}}>
            <div style={{flex: 1}}>
              {(task?.owners?.length || task.deadline) && (
                <React.Fragment>
                  {task.owners?.map((owner) => (
                    <div
                      key={owner?.profile}
                      className='p-1 tm-task-team-bullet-avatar my-1'
                      style={{backgroundColor: owner?.team?.color}}
                      data-bs-toggle='tooltip'
                      data-bs-html='true'
                      title={owner.team?.tooltip}>
                      {owner?.team?.name}
                    </div>
                  ))}
                </React.Fragment>
              )}
            </div>
            {task.deadline && (
              <div style={{flex: 1}}>
                <Tag className='tm-task-item-tag' color='default' icon={<CalendarOutlined />}>
                  {task.deadline}
                </Tag>
              </div>
            )}
          </div>
        </Col>
        <Col span={2}>
          <ActionMenu task={rawTask} />
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default TaskManagementTaskItem
